import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
 
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "office",
    },
  },
  {
    path: "/about/:url",
    name: "about",
    meta: {
      title: "office",
    },
    component: () => import("../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

//解决重复路由问题  路由多次点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
