import axios from "axios";
import store from "./../store/index";

// let BASEURL = "http://61.4.121.85:3000/";
let BASEURL = "/";

let HTTP = axios.create({
  baseURL: BASEURL,
  timeout: 20000,
  headers: {
    // Authorization: TOKEN,
    "Content-Type": "application/json",
    // 'content-type': ' application/x-www-form-urlencoded; charset=UTF-8'
    // 'content-type': 'multipart/form-data'
  },
});

// 请求拦截
HTTP.interceptors.request.use((config) => {
  console.log("拦截器", config.baseURL);

  return config;
});

// 响应拦截器
HTTP.interceptors.response.use(
  (response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // console.log("响应拦截器", response);

    return response.data;
  },
  (error) => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么

    console.log("响应拦截错误", error);
    console.log("网络请求超时");
    store.commit("showState");

    return Promise.reject(error);
  }
);

export default HTTP;
